import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Leagues from "../components/Leagues";
import Standings from "../components/Standings";
import Schedule from "../components/Schedule";
import Betslip from "../components/Betslip";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";

const Mybets = () => {
    const [bets, setBets] = useState([]);

    const [activeBets, setActiveBets] = useState([]);
    const [settledBets, setSettledBets] = useState([]);
    const [activeTab, setActiveTab] = useState("active");

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

    const getActiveBets = async () => {
        const payload = objectToFormData({ api_username: "FANDEX_DEV_TEAM", api_password: "FANDEX_PASSWORD_@#", user_id: localStorage.getItem("userId") })
        const response = await CRUDServices.POST("get_fantasy_bet_slip_by_user_id", payload);
        setActiveBets(response.data.data)
    }

    const getSettledBets = async () => {
        const payload = objectToFormData({ api_username: "FANDEX_DEV_TEAM", api_password: "FANDEX_PASSWORD_@#", user_id: localStorage.getItem("userId") })
        const response = await CRUDServices.POST("get_settled_bets_by_user_id", payload);
        setSettledBets(response.data.data)
    }

    useEffect(() => {
        getActiveBets();
        getSettledBets();
    }, [])

    return (
        <div className="dashboard-body">
            <Header />
            <div className="container-fluid mb-3 mt-3">
                <div className="row">
                    {/* Left side */}
                    <div className="col-lg-3 col-md-12 col-sm-12 orderThird">
                        <Leagues />
                        <Standings />
                        <Schedule />
                    </div>
                    {/* Middle */}
                    <div className="col-lg-9 col-md-12 col-sm-12 mb-3 oderSecond">
                        <div className="sport-mid-card">
                            <div className="sport-heading">
                                <p className="mb-0">My Bets</p>
                            </div>
                            <div className="p-3 mybets">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a className={activeTab == "active" ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => setActiveTab("active")} >
                                            Active
                                        </a>
                                    </li>
                                    <li className="nav-item" >
                                        <a className={activeTab == "settled" ? "nav-link active" : "nav-link"} onClick={() => setActiveTab("settled")}>
                                            Settled
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            {activeTab == "active" ?
                                <div className="table-scroll">

                                    {activeBets.length > 0 ? activeBets?.map((item) => {

                                        let date = new Date(String(item.gametime));
                                        let day = date.getDate();
                                        day = day < 10 ? "0" + day : day;
                                        let monthNum = date.getMonth();
                                        let month = months[monthNum];
                                        let weekDayNum = date.getDay();
                                        let weekDay = days[weekDayNum];

                                        let hours = date.getHours();
                                        let minutes = date.getMinutes();
                                        let ampm = hours >= 12 ? "pm" : "am";
                                        hours = hours % 12;
                                        hours = hours ? hours : 12; // the hour '0' should be '12'
                                        minutes = minutes < 10 ? "0" + minutes : minutes;
                                        let strTime = hours + ":" + minutes + " " + ampm;

                                        return (
                                            <div className="table-bg" style={{ border: "1px solid" }}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="title-section">
                                                        <p className="teamLogo"><img src={item.image_path} alt="logo" /></p>
                                                        <div>
                                                            <p className="f-2 mb-0 bold" style={{ fontWeight: "500px" }}>{item.bet_type == "total" ? item.total_ou == "o" ? "Over" : "Under" : item.BetTeamName}</p>
                                                            <p className="f-1" >{item.bet_type.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                    <p>{item.odds}</p>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="f-1">{item.HomeTeam} @ {item.AwayTeam}</p>
                                                    <p className="f-1">{strTime}</p>
                                                </div>
                                                <hr className='line mt-0 mb-0' />
                                                <div className="d-flex justify-content-between align-items-center mybet-wager">
                                                    <div>
                                                        <span className="f-2">${item.bet_amount}</span> <br /> <span className="f-1">TOTAL WAGER</span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="f-2">${item.payouts}</span> <br /> <span className="f-1">TO WIN</span>
                                                    </div>
                                                </div>
                                                <hr className='line mt-0' />
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="f-1 mb-0">BET ID: {item.bet_id} </p>
                                                    <p className="f-1 mb-0">PLACED: {item.created_on_time}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : <span className='center color-white'>No active bets...</span>}

                                </div>
                                :
                                <div className="table-scroll">

                                    {settledBets.length > 0 ? settledBets.map((item) => {

                                        let date = new Date(String(item.gametime));
                                        let day = date.getDate();
                                        day = day < 10 ? "0" + day : day;
                                        let monthNum = date.getMonth();
                                        let month = months[monthNum];
                                        let weekDayNum = date.getDay();
                                        let weekDay = days[weekDayNum];

                                        let hours = date.getHours();
                                        let minutes = date.getMinutes();
                                        let ampm = hours >= 12 ? "pm" : "am";
                                        hours = hours % 12;
                                        hours = hours ? hours : 12; // the hour '0' should be '12'
                                        minutes = minutes < 10 ? "0" + minutes : minutes;
                                        let strTime = hours + ":" + minutes + " " + ampm;

                                        return (
                                            <div className="table-bg" style={{ border: "1px solid" }}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="title-section">
                                                        <p className="teamLogo"><img src={item.image_url} alt="logo" /></p>
                                                        <div>
                                                            <p className="f-2 mb-0 bold" style={{ fontWeight: "500px" }}>{item.bet_type == "total" ? item.total_ou == "o" ? "Over" : "Under" : item.BetTeamName}</p>
                                                            <p className="f-1" >{item.bet_type.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                    <p>{item.settled_amount}</p>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="f-1">{item.HomeTeam} @ {item.AwayTeam}</p>
                                                    <p className="f-1">{strTime}</p>
                                                </div>
                                                <hr className='line mt-0 mb-0' />
                                                <div className="d-flex justify-content-between align-items-center mybet-wager">
                                                    <div>
                                                        <span className="f-2">${item.bet_amount}</span> <br /> <span className="f-1">TOTAL WAGER</span>
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="f-2">${item.settled_amount < 0 ? "0" : item.settled_amount}</span> <br /> <span className="f-1">RETURNED</span>
                                                    </div>
                                                </div>
                                                <hr className='line mt-0' />
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <p className="f-1 mb-0">BET ID: {item.bet_id} </p>
                                                    <p className="f-1 mb-0">PLACED: {item.created_on_time}</p>
                                                </div>
                                            </div>
                                        )
                                    }) : <span className='center color-white'>No settled bets...</span>}

                                </div>
                            }

                        </div>
                    </div>
                    {/* Right side */}
                    {/* <Betslip bets={bets} setBets={setBets} /> */}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Mybets;
