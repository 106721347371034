import React from 'react'
import { NavLink } from 'react-router-dom'
import Coming from '../assets/images/coming.png'

const ComingSoon = () => {
  return (
    <div className='container-fluid mb-3 mt-1'>
      <div className='row'>
        <div className='col-12'>
          <div className='coming-soon-bg'>
            <img src={Coming} alt='coming' className='img-fluid mt-0' />
            <div className='coming-text'><p>We are almost ready to launch. Something awesome is coming soon.</p></div>
           <div> <NavLink to="/" className="btn btn-loginbet">Back to Home</NavLink></div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ComingSoon