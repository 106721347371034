import React, { useEffect, useState } from "react";
import Counter from "../components/Countdown";
import lockImg from "../assets/images/lock-img.png"
import { checkExistenceInBets } from "../functions"

const League = ({ item, handleAddBet, handleClick, bets }) => {

  const [isLive, setIsLive] = useState(false);
  console.log(item, "item");
  const [gameOver, setGameOver] = useState(false);
  const [winner, setWinner] = useState("");
  const [isPresentInBets, setIsPresentInBets] = useState([]);

  let date = new Date(item.scheduledate);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;

  useEffect(() => {
    //setting live to false forcefully because it was not getting reset to false on rerender
    setIsLive(false);

    var matchDate = new Date(item.scheduledate).getDate() + 1;
    var matchMonth = new Date(item.scheduledate).getMonth() + 1;
    var currentDate = new Date().getDate() + 1;
    var currentMonth = new Date().getMonth() + 1;

    if (Number(item.Q4AwayScore) > Number(item.Q4HomeScore)) {
      setWinner("away")
    }
    else {
      setWinner("home")
    }

    setGameOver(matchDate <= currentDate && matchMonth <= currentMonth);

    //check existence in bets
    var arr = [];
    [["home", "spread"], ["home", "money"], ["home", "total"], ["away", "spread"], ["away", "money"], ["away", "total"]].map((data) => {
      const bool = checkExistenceInBets(item["TeamId" + data[0]] + data[1] + item.gameid, bets)
      arr.push(bool);
    })
    setIsPresentInBets(arr);
  }, [item, bets])

  return (
    <div className="tableresponsive table-bg" onClick={() => handleClick(item)}>
      <div className="time-section">
        <span className="league-time">
          <i class="fa fa-clock-o" aria-hidden="true"></i> {strTime}
        </span>
        <p className="mb-0">
          <span className="league-timer">
            {gameOver ?
              <p className="mb-0 league-timer">FINAL</p> :
              isLive ?
                <p className="mb-0 league-timer" >
                  NO MORE BETS
                </p>
                :
                <Counter date={item.scheduledate} setIsLive={setIsLive} />
            }
          </span>
        </p>
        <span></span>
      </div>
      <hr className="line mt-0 mb-0" />
      <div className="tableresponsive-head mt-0">
        <div className="d-flex justify-content-between align-items-center head-text">
          <p className='mb-0'>Spread</p>
          <p className='mb-0'>Money</p>
          <p className='mb-0'>Total</p>
        </div>
      </div>
      <div className="d-flex justify-content-between LiveGameM">
        <div className="text-white mb-2 mb-sm-0 teamLogo score-main">
          <div>
            <img src={item.image_url_home} alt="player" className="avatar" />
            <span className="mb-0 f-4 f-1mobile mx-2">{item.TeamNamehome}</span>
          </div>
          <div>
            {gameOver && <span className="mb-0 f-4 f-1mobile mx-2">{item.Q4HomeScore}</span>}
            {gameOver && winner == "home" ? <span className="mb-0 f-4 f-1mobile mx-2 win-icon color-blue">W</span> : <span className="mb-0 f-4 f-1mobile mx-2 win-text">W</span>}
          </div>
        </div>
        <div className="grid-button league-section">

          {isLive || gameOver ?
            <>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => { e.stopPropagation() }}
              >

                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => e.stopPropagation()}
              >

                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
                {/* <div className="lock-icon"><img src={lockImg} alt="lockimg" /></div> */}
              </div>
            </>
            :
            <>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "home", "spread", item)}
              >
                <div className={isPresentInBets[0] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>
                  <span className="d-block">{item.spreadhome}</span>
                  {/* <span>{item.spreadOddshome}</span> */}

                </div>

              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "home", "money", item)}
              >
                <div className={isPresentInBets[1] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>
                  <span className="money-box">{item.moneyhome}</span>
                </div>

              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "home", "total", item)}
              >
                <div className={isPresentInBets[2] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>
                  <span className="d-block over-under-txt">O {item.totalhome}</span>
                  {/* <span>{item.totalOddshome}</span> */}
                </div>

              </div>
            </>
          }
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 LiveGameM">
        <div className="text-white mb-2 mb-sm-0 teamLogo score-main">
          <div>
            <img src={item.image_url_away} alt="player" className="avatar " />
            <span className="mb-0 f-4 f-1mobile mx-2">{item.TeamNameaway}</span>
          </div>
          <div>
            {gameOver && <span className="mb-0 f-4 f-1mobile mx-2">{item.Q4AwayScore}</span>}
            {gameOver && winner == "away" ? <span className="mb-0 f-4 f-1mobile mx-2 win-icon color-blue">W</span> : <span className="mb-0 f-4 f-1mobile mx-2 win-text">W</span>}
          </div>
        </div>
        <div className="grid-button league-section">
          {isLive || gameOver ?
            <>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => e.stopPropagation()}
              >

                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => e.stopPropagation()}
              >

                <div className="btn btn-outline-secondary btn-sm btn-width">
                  <img className="lock-icon" src={lockImg} alt="lockimg" />

                </div>
              </div>
            </>
            :
            <>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "away", "spread", item)}
              >
                <div className={isPresentInBets[3] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>

                  <span className="d-block">{item.spreadaway}</span>
                  {/* <span>{item.spreadOddsaway}</span> */}
                </div>

              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "away", "money", item)}
              >
                <div className={isPresentInBets[4] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>

                  <span className="money-box">{item.moneyaway}</span>
                  <span></span>
                </div>

              </div>
              <div
                className={isLive ? "btn-score lock" : "btn-score"}
                onClick={(e) => handleAddBet(e, "away", "total", item)}
              >
                <div className={isPresentInBets[5] ? "btn btn-outline-secondary btn-sm btn-width highlight" : "btn btn-outline-secondary btn-sm btn-width"}>

                  <span className="d-block over-under-txt">U {item.totalaway}</span>
                  {/* <span>{item.totalOddsaway}</span> */}
                </div>

              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default League;
