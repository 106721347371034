import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";
import bg from "../assets/images/bg.png";
import vs from "../assets/images/vs.png";
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import n1 from "../assets/images/n1.png";
import q1 from "../assets/images/q1.png";
import sample from "../assets/animations/sample.mp4";
import sample2 from "../assets/animations/sample2.mp4";

import judBuechler from "../assets/animations/jud-buechler.mp4";
import jackHaley from "../assets/animations/jack-haley-1.mp4";
import jasonCaffey from "../assets/animations/jason-caffey-1.mp4";
import randyBrown from "../assets/animations/randy-brown-1.mp4";

import first from "../assets/animations/first.mp4";
import second from "../assets/animations/second.mp4";
import third from "../assets/animations/third.mp4";
import fourth from "../assets/animations/fourth.mp4";
import gameOver from "../assets/animations/GameOver.mp4";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const SingleLeague = () => {
  const location = useLocation();
  const matchData = location?.state?.data;

  const [homeTeamPlayersData, setHomeTeamPlayersData] = useState([]);
  const [awayTeamPlayersData, setAwayTeamPlayersData] = useState([]);
  const [quarter, setQuarter] = useState("");
  console.log(quarter, "quarter");

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    timer == 22 && setQuarter("Quarter1");
    timer == 40 && setQuarter("Quarter2");
    timer == 58 && setQuarter("Quarter3");
    timer == 76 && setQuarter("Quarter4");
  }, [timer]);

  const getTeamPlayersInfo = async (teamId, team) => {
    var payload = objectToFormData({
      team_id: teamId,
      api_username: "FANDEX_DEV_TEAM",
      api_password: "FANDEX_PASSWORD_@#",
    });
    const response = await CRUDServices.POST("get_fantasy_players_by_team_id", payload);

    if (team == "home") {
      setHomeTeamPlayersData(response.data.data);
    } else {
      setAwayTeamPlayersData(response.data.data);
    }
  };

  useEffect(() => {
    getTeamPlayersInfo(matchData?.TeamIdhome, "home");
    getTeamPlayersInfo(matchData?.TeamIdaway, "away");
  }, [matchData]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setTimer(timer + 1);
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [timer]);

  const renderTabs = useMemo(() => {
    return (
      <Tabs activeKey={quarter} onSelect={(k) => setQuarter(k)} id="uncontrolled-tab-example" className="mb-3">
        {timer >= 22 && (
          <Tab eventKey="Quarter1" title="Quarter1">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q1HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q1AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 40 && (
          <Tab eventKey="Quarter2" title="Quarter2">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q2HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q2AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 58 && (
          <Tab eventKey="Quarter3" title="Quarter3">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q3HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q3AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
        {timer >= 76 && (
          <Tab eventKey="Quarter4" title="Quarter4">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Score</th>
                  <th>Team</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matchData.TeamNamehome}</td>
                  <td>{matchData.Q4HomeScore}</td>
                  <td>{matchData.TeamNameaway}</td>
                  <td>{matchData.Q4AwayScore}</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        )}
      </Tabs>
    );
  }, [quarter]);

  return (
    <div className="sport-mid-card">
      <div className="sport-heading">
        <p className="mb-0">
          <NavLink to="/">
            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
          </NavLink>{" "}
          | NBA LEAGUE
        </p>
      </div>

      <div className="parent">
        {timer < 4 && (
          // <div>
          //     <img src={bg} alt='bg' className='img-fluid' />
          //     <div>
          //         <img src={vs} className='vs' alt='vs' />
          //         <img src={n1} className='team1' alt='teamname' />
          //         <img src={n1} className='team2' alt='teamname' />
          //         <img src={t1} className='teamlogo1' alt='teamname' />
          //         <img src={t2} className='teamlogo2' alt='teamname' />
          //         <p className='teamname1'>{matchData.TeamNamehome.toUpperCase()}</p>
          //         <p className='teamname2'>{matchData.TeamNameaway.toUpperCase()}</p>
          //     </div>
          // </div>

          <div>
            <img src={bg} alt="bg" className="img-fluid" />
            <div>
              <img src={q1} className="vs" alt="q1" />
              <img src={n1} className="team1" alt="teamname" />
              <img src={n1} className="team2" alt="teamname" />
              <img src={t1} className="teamlogo1" alt="teamname" />
              <img src={t2} className="teamlogo2" alt="teamname" />
              <p className="teamname1">Score 91</p>
              <p className="teamname2 q-score">Score 112</p>
            </div>
          </div>
        )}
        {timer > 3 && timer < 7 && (
          <>
            <div>
              <video src={first} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 6 && timer < 17 && (
          <>
            <div className="video-container">
              <video src={judBuechler} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 16 && timer < 22 && (
          <>
            <div className="video-container">
              <video src={jackHaley} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 21 && timer < 25 && (
          <>
            <div>
              <video src={second} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 24 && timer < 35 && (
          <>
            <div className="video-container">
              <video src={jasonCaffey} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 34 && timer < 40 && (
          <>
            <div className="video-container">
              <video src={randyBrown} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 39 && timer < 43 && (
          <>
            <div>
              <video src={third} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 42 && timer < 53 && (
          <>
            <div className="video-container">
              <video src={judBuechler} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 52 && timer < 58 && (
          <>
            <div className="video-container">
              <video src={jackHaley} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 57 && timer < 61 && (
          <>
            <div>
              <video src={fourth} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 60 && timer < 71 && (
          <>
            <div className="video-container">
              <video src={jasonCaffey} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 70 && timer < 76 && (
          <>
            <div className="video-container">
              <video src={randyBrown} type="video/mp4" width="auto" height="600px" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
        {timer > 75 && (
          <>
            <div>
              <video src={gameOver} type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" />
            </div>
          </>
        )}
      </div>

      <div className="p-3 single-detail">
        {timer >= 22 && <div className="accordian-tabs my-4">{renderTabs}</div>}
        {/* <Accordion >
                    <Accordion.Item eventKey="4" className='mb-3'>
                        <Accordion.Header><img src={matchData?.image_url_home} alt='matchimage' className='innerteam-logo' />{matchData?.TeamNamehome}</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Table responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Player Name</th>
                                            <th>GS</th>
                                            <th>MP</th>
                                            <th>DRB</th>
                                            <th>STL</th>
                                            <th>BLK</th>
                                            <th>TOV</th>
                                            <th>PTS</th>
                                            <th>ptsmin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {homeTeamPlayersData?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.Playersname}</td>
                                                    <td>{item.GS}</td>
                                                    <td>{item.MP}</td>
                                                    <td>{item.DRB}</td>
                                                    <td>{item.STL}</td>
                                                    <td>{item.BLK}</td>
                                                    <td>{item.TOV}</td>
                                                    <td>{item.PTS}</td>
                                                    <td>{item.ptsmin}</td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className='mb-3'>
                        <Accordion.Header><img src={matchData?.image_url_away} alt='teamlogo' className='innerteam-logo' /> {matchData?.TeamNameaway}</Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Table responsive="sm">
                                    <thead>
                                        <tr>
                                            <th>Player Name</th>
                                            <th>GS</th>
                                            <th>MP</th>
                                            <th>DRB</th>
                                            <th>STL</th>
                                            <th>BLK</th>
                                            <th>TOV</th>
                                            <th>PTS</th>
                                            <th>ptsmin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {awayTeamPlayersData?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.Playersname}</td>
                                                    <td>{item.GS}</td>
                                                    <td>{item.MP}</td>
                                                    <td>{item.DRB}</td>
                                                    <td>{item.STL}</td>
                                                    <td>{item.BLK}</td>
                                                    <td>{item.TOV}</td>
                                                    <td>{item.PTS}</td>
                                                    <td>{item.ptsmin}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion> */}
      </div>
    </div>
  );
};

export default SingleLeague;
