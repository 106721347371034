import React, { useState } from 'react'
import Header from '../components/Header'
import Leagues from '../components/Leagues'
import Standings from '../components/Standings'
import Schedule from '../components/Schedule'
import Betslip from '../components/Betslip'
import Footer from '../components/Footer'
import { Outlet } from 'react-router-dom'

const HomeLayout = () => {

    const [bets, setBets] = useState([]);
    const [myBet, setMyBet] = useState(false);

    return (
        <div className='dashboard-body'>
            <Header />
                <div className="container-fluid mb-3 mt-3">
                    <div className="row Cstmdirection">
                        {/* Left side */}
                        <div className="col-lg-3 mb-3 orderThird">
                            {/* <!-- ALL SPORTS --> */}
                            <Leagues />
                            <Standings />
                            <Schedule />
                        </div>
                        {/* Middle */}
                        <div className="col-lg-6 mb-3 oderSecond">
                            <Outlet context={[bets, setBets]}/>
                        </div>
                        {/* Right side */}
                        <Betslip bets={bets} setBets={setBets} myBet={myBet}/>
                        <div className='col-md-12 btn-grp'>
                            <button className='btn btn-primary' id='my_bet' onClick={()=> setMyBet(!myBet)}>My Bet</button>
                        </div>
                    </div>
                </div>
            <Footer />
        </div >
    )
}

export default HomeLayout