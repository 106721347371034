import React, { useEffect, useState } from 'react';
import logo from "../assets/images/logo.png"
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import { CRUDServices } from '../services/CRUDServices';
import { objectToFormData } from '../functions';
import { toast } from 'react-toastify';

const ResetPassword = () => {

    const [viewPassword, setViewPassword] = useState(false);
    const [viewconfirm_password, setViewconfirm_password] = useState(false);
    const [token, setToken] = useState("");

    const navigate = useNavigate();

    const resetPasswordSchema = Yup.object().shape({
        password: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character!"
        ).required("Please enter your password!"),
        confirm_password: Yup.string().required("Please confirm your password!").oneOf([Yup.ref('password'), null], "Passwords do not match"),
    })

    const initialValues = {
        password: "",
        confirm_password: "",
    }

    const handleSumbit = async (values) => {
        console.log(values, "values");
        const payload = objectToFormData({ ...values, api_username: "FANDEX_DEV_TEAM", api_password: "FANDEX_PASSWORD_@#", token });
        const response = await CRUDServices.POST("update_password", payload);
        if (response.status == 200) {
            navigate("/login");
            toast.success(response.data.message);
        }
        else {
            var errors = response.data.errors;

            for (var [key, error] of Object.entries(errors)) {
                toast.error(error);
            }
        }
    }

    useEffect(() => {
        var path = window.location.pathname;
        var token = path.slice(16);
        setToken(token);
    })

    return (
        <div className="main-bg">
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="card shadow">
                            <NavLink className="logo" to="/">
                                <img src={logo} className="img-logo" />
                            </NavLink>
                            <div className="card-title text-center">
                                <p className="p-3">Reset Your Password</p>
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={resetPasswordSchema}
                                    onSubmit={handleSumbit}
                                >
                                    {({ errors, touched }) => {
                                        return (
                                            <Form>
                                                <div className="mb-4">
                                                    <label htmlFor="password" className="form-label">Enter new Password</label>
                                                    <div className="input-group flex-nowrap">
                                                        <Field type={viewPassword ? "text" : "password"} className="form-control" placeholder="Enter Your Password"
                                                            aria-label="password" name="password" autocomplete="off" />
                                                        <span className="input-group-text" id="addon-wrapping" onClick={() => setViewPassword(!viewPassword)}><i className={viewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                                    </div>
                                                    {errors.password && touched.password ?
                                                        <div className='error'>{errors.password}</div> : null
                                                    }
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="confirm_password" className="form-label">Re-enter your new password</label>
                                                    <div className="input-group flex-nowrap">
                                                        <Field type={viewconfirm_password ? "text" : "password"} className="form-control"
                                                            placeholder="Enter Your  Confirm Password" aria-label="password" name="confirm_password" autocomplete="off" />
                                                        <span className="input-group-text" id="addon-wrapping" onClick={() => setViewconfirm_password(!viewconfirm_password)}><i className={viewconfirm_password ? "fa fa-eye" : "fa fa-eye-slash"}></i></span>
                                                    </div>
                                                    {errors.confirm_password && touched.confirm_password ?
                                                        <div className='error'>{errors.confirm_password}</div> : null
                                                    }
                                                </div>
                                                <div className="d-grid">
                                                    <button type="submit" className="btn text-light p-3">Reset</button>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword