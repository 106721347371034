import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer">
            <span>
                &copy; Fandex 2024
            </span>
            <ul>
                <li className="nav-item"><NavLink to="aboutus">ABOUT US</NavLink></li>
                <li className="nav-item"><NavLink to="/disclaimer">DISCLAIMER</NavLink></li>
                <li className="nav-item"><NavLink to="/privacypolicy">PRIVACY POLICY</NavLink></li>
                <li className="nav-item"><NavLink to="/contactus">CONTACT US</NavLink></li>

            </ul>
        </footer>
    )
}

export default Footer