import React, { memo, useEffect, useState } from "react";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";
import { Audio } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Leagues = () => {

  const navigate = useNavigate();

  const [leagues, setLeagues] = useState([]);

  const getFantasyLeague = async () => {
    var payload = objectToFormData({
      api_username: "FANDEX_DEV_TEAM",
      api_password: "FANDEX_PASSWORD_@#",
    });
    const response = await CRUDServices.POST("get_fantasy_league", payload);
    setLeagues(response?.data?.data);
  };

  useEffect(() => {
    getFantasyLeague();
  }, []);

  return (
    <div className="col-lg-12">
      <div className="sport_card">
        <div className="sport-heading">
          <p className="mb-0">LEAGUES</p>
        </div>
        <div className="table-league-left">
          {leagues?.length !== 0 ? (
            leagues?.map((item) => {
              return (
                <div className="d-flex justify-content-start align-items-center table-head" onClick={() => navigate(("/" + item.exchangecode).toLowerCase())}>
                  <img className="svg-icon" src={item.image_url} alt="league_image" />
                  <div className={item.exchangecode === "NBA"? "mb-0 color-blue" : "mb-0"} >
                    {item.exchangecode}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex-standings">
              <Audio
                height="180"
                width="120"
                radius="200"
                color="white"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Leagues);
