import React, { memo, useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { CRUDServices } from "../services/CRUDServices";
import { objectToFormData } from "../functions";

const Standings = () => {
    const [standings, setStandings] = useState([]);

    const getFantasyStandingByExchange = async () => {
        var payload = objectToFormData({
            exchange_slug: "nba",
            api_username: "FANDEX_DEV_TEAM",
            api_password: "FANDEX_PASSWORD_@#",
        });
        const response = await CRUDServices.POST(
            "get_fantasy_standing_by_exchange",
            payload
        );
        setStandings(response?.data?.data);
    };

    useEffect(() => {
        getFantasyStandingByExchange();
    }, []);

    return (
        <div className="col-lg-12 mt-3 standing-section">
            <div className="sport_card">
                <div className="sport-heading">
                    <p className="mb-0">STANDINGS</p>
                </div>
                <div className="tableresponsive">
                    {standings?.length !== 0 ? (
                        <div>
                            <div className="d-flex justify-content-between align-items-center table-head bg-table standingsec">
                                <p className="pl-1 mb-0">Team</p>
                                <div className="standing-hpts">
                                <p className="mb-0">
                                    W
                                </p>
                                <p className="mb-0">
                                    L
                                </p>
                                <p className="mb-0 mr-10">Pct</p>
                                    </div>
                            </div>
                            <div className="table-scroll-left standings-section">
                                {standings?.map((item) => {
                                    var stats = item.Win_Loss.split(" ");
                                    return (
                                        <div className="d-flex justify-content-between align-items-center table-head">
                                            <p className="mb-0 teamLogo">
                                                <img src={item.image_url} />
                                                <span className="ml-10">{item.TeamName}</span>
                                            </p>
                                            <div className="standing-pts">
                                                <p className="mb-0">{stats[0]}</p>
                                                <p className="mb-0">{stats[1]}</p>
                                                <p className="mb-0">{item.pct}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="flex-standings">
                            <Audio
                                height="180"
                                width="120"
                                radius="200"
                                color="white"
                                ariaLabel="loading"
                                wrapperStyle
                                wrapperClass
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(Standings);
