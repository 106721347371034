import React from 'react'
import { Field, Form, Formik } from 'formik';

const ContactUs = () => {
  return (
    <div className="dashboard-body">
      <div className='page-bg'>
        <h1 className='mb-2 mt-5'>Get in touch with us</h1>
        <p className='faq-stitle'>Fill up the form and our team will get back to you within 24 hours</p>
        <div className="container p-3">

          <div className="row">

            <div className="col-md-12">
              <Formik>

                <Form>
                  <div className='contactform-bg'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">Your Name</label>
                          <Field type="text" className="form-control" id="name" placeholder="What's your name?" autocomplete="off" />

                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">Your email address</label>
                          <Field type="email" className="form-control" id="name" placeholder="What's your Email?" autocomplete="off" />

                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'>
                        <div className="mb-3">
                          <label htmlFor="phone" className="form-label">Phone</label>
                          <Field type="text" className="form-control" id="name" placeholder="(123) 456 7890" autocomplete="off" />

                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="mb-3">
                          <label htmlFor="subject" className="form-label">Subject</label>
                          <Field type="text" className="form-control" id="name" placeholder="Enter your Subject?" autocomplete="off" />

                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-md-12'>
                        <div className="mb-3">
                          <label htmlFor="message" className="form-label">Message</label>
                          <textarea className="text-area w-100" placeholder='I would like to get in touch with you...' />
                        </div>
                      </div>

                    </div>

                    <div className="">
                      <button type="submit" className="btn submit-btn text-light">Send Message</button>
                    </div>
                  </div>
                </Form>

              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs