import React from "react";
import logo from "../assets/images/logo.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = () => {

    const token = localStorage.getItem("token");

    const wallet = useSelector((state)=>state.wallet);

    return (

        <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
            <Container fluid>
                <NavLink to="/"><img src={logo} className="h-8 " style={{ maxHeight: "137px", }} alt="..." /></NavLink>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="justify-content-center flex-grow-1 pe-3 main-menu" style={{gap: 30}}>
                        <NavLink to="/nba">Sports</NavLink>
                        <NavLink to="/howtoplay">How to Play</NavLink>
                        <NavLink to="/faq">FAQ</NavLink>
                        <NavLink to="/contactus">Contact Us</NavLink>
                    </Nav>
                    <Nav>
                        {!token ? (
                            <NavLink className="user-icon" to="/login">
                                <i class="fa fa-user-circle" aria-hidden="true"></i>
                            </NavLink>
                        ) : (
                            <NavLink className="user-icon" to="/login">
                              <span className="logout">Logout</span> <i class="fa fa-sign-out" aria-hidden="true"></i>
                            </NavLink>
                        )}
                    </Nav>
                    <Nav>
                        {token &&
                            <>
                                <div className="bottom_text text-white">
                                    <p className="header-amount">${wallet.balance}</p>
                                    <span className="header-bal">BALANCE</span>
                                </div>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
};

export default Header;
