import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CRUDServices } from '../services/CRUDServices';
import { objectToFormData } from '../functions';
import { Audio } from 'react-loader-spinner';
import League from '../components/League';

const AllLeagues = () => {

    const navigate = useNavigate();
    const [bets, setBets] = useOutletContext();
    const [fantasyTeamDetailExchange, setFantasyTeamDetailExchange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState("")
    const [currentDate, setCurrentDate] = useState("")
    const [minDate, setMinDate] = useState("")


    const getFantasyTeamDetailExchange = async (date) => {
        setLoading(true);
        var payload = objectToFormData({ exchange_slug: "nba", api_username: "FANDEX_DEV_TEAM", api_password: "FANDEX_PASSWORD_@#", date: date });
        const response = await CRUDServices.POST("get_fantasy_team_detail_exchange", payload);
        setLoading(false);
        console.log(response, "response");
        if (response.status == 200) {
            setFantasyTeamDetailExchange(response?.data?.data);
        }
        else {
            setFantasyTeamDetailExchange([]);
        }
    }

    useEffect(() => {
        getDates();
    }, [])

    const compareDates = (scheduleDate) => {
        const date = new Date();
        return date === scheduleDate;
    }

    const handleClick = (data) => {
        navigate('/singlePage', { state: { data: data } });
    }

    //to get and format today's date and disable previous dates in calendar
    const getDates = () => {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var minDate = year + '-' + month + '-' + day;
        setMinDate(minDate);

        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var today = now.getFullYear() + "-" + (month) + "-" + (day);
        setCurrentDate(today);
        getFantasyTeamDetailExchange(today);
    }

    const handleDateChange = (e) => {
        setCurrentDate(e.target.value)
        getFantasyTeamDetailExchange(e.target.value);
        // alert("Date changed")
    }

    const handleAddBet = (e, team, betType, leagueData) => {

        e.stopPropagation();
        const payload = {
            team: leagueData["TeamName" + team],
            betType: betType,
            // odds: betType == "money" ? leagueData[betType + team] : leagueData[betType + "Odds" + team],
            odds: leagueData[betType + team],
            homeTeam: leagueData.TeamNamehome,
            awayTeam: leagueData.TeamNameaway,
            time: Date.now(),
            leagueData,
            betTeam: team,
            betType,
            teamId: leagueData["TeamId" + team],
            gameId: leagueData.gameid,
            wagerAmount: 0.00,
            winningAmount: 0.00,
        }

        var arr = [...bets];
        var isBetExists = arr.findIndex((bet) => bet.id == (leagueData["TeamId" + team] + betType + leagueData.gameid))
        if (isBetExists == -1) {
            arr.push({ id: leagueData["TeamId" + team] + betType + leagueData.gameid, data: payload })
        }
        else {
            arr.splice(isBetExists, 1)
            setBets(arr);
        }
        localStorage.setItem("bets", JSON.stringify(arr));
        setBets(arr);
    }



    return (
        <div className="sport-mid-card">
            <div className="sport-heading">
                <p className="mb-0">NBA LEAGUE</p>
                <div>
                    {/* to disable previous dates */}
                    {/* <input type='date' className='league-date' min={minDate} onChange={(e) => handleDateChange(e)} value={currentDate} /> */}
                    <input type='date' className='league-date' onChange={(e) => handleDateChange(e)} value={currentDate} />
                </div>
            </div>
            {/* <div className="tableresponsive-head bg-table">
                <div className="d-flex justify-content-between align-items-center head-text">
                    <p className='mb-0'>Spread</p>
                    <p className='mb-0'>Money</p>
                    <p className='mb-0 mr-10'>Total</p>
                </div>
            </div> */}
            {!loading ? <div className="table-scroll">
                {fantasyTeamDetailExchange?.length != 0 ? fantasyTeamDetailExchange?.map((item) => {


                    return (
                        <League handleAddBet={handleAddBet} handleClick={handleClick} item={item} bets={bets}/>
                    )
                }) :
                    <div className='flex-standings'>
                        <span className='center color-white'>No data found...</span>
                    </div>}

            </div> :
                <div className='flex-standings'>
                    <Audio
                        height="180"
                        width="120"
                        radius="200"
                        color="white"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass
                    />
                </div>
            }

        </div>
    )
}

export default AllLeagues