import React, { memo, useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { objectToFormData } from "../functions";
import { CRUDServices } from "../services/CRUDServices";

const Schedule = () => {
    const [schedules, setSchedules] = useState([]);

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

    const getFantasyScheduleByleague = async () => {
        var payload = objectToFormData({
            exchange_slug: "nba",
            api_username: "FANDEX_DEV_TEAM",
            api_password: "FANDEX_PASSWORD_@#",
        });
        const response = await CRUDServices.POST(
            "get_fantasy_schedule_by_exchange",
            payload
        );
        setSchedules(response?.data?.data);
    };

    useEffect(() => {
        getFantasyScheduleByleague();
    }, []);

    return (
        <div className="col-lg-12 mt-3">
            <div className="sport_card">
                <div className="sport-heading">
                    <p className="mb-0">SCHEDULE</p>
                </div>
                <div className="tableresponsive">
                    {/* <div className="d-flex justify-content-between align-items-center table-head bg-table">
            <p className="mb-0">Date & Time</p>
            <p className="mb-0">Team</p>
          </div> */}
                    <div className="table-scroll-left schedule-section">
                        {schedules?.length !== 0 ? (
                            schedules?.map((item) => {
                                let date = new Date(String(item.scheduledate));
                                let day = date.getDate();
                                day = day < 10 ? "0" + day : day;
                                let monthNum = date.getMonth();
                                let month = months[monthNum];
                                let weekDayNum = date.getDay();
                                let weekDay = days[weekDayNum];

                                let hours = date.getHours();
                                let minutes = date.getMinutes();
                                let ampm = hours >= 12 ? "pm" : "am";
                                hours = hours % 12;
                                hours = hours ? hours : 12; // the hour '0' should be '12'
                                minutes = minutes < 10 ? "0" + minutes : minutes;
                                let strTime = hours + ":" + minutes + " " + ampm;

                                return (<>
                                    <div className="schedule-area">
                                        <div className="schedule-content table-head border-bottom border-dark" >
                                            <div className="schedule-date">
                                                <span>{`${month}, ${day} `}</span> <br/>
                                                <span>{`${strTime}`}</span>
                                            </div>
                                            <div className="schedule-team">
                                                <div className="scheduleTiles">
                                                    <div className="teamLogo"><img src={item.image_url_home} alt="team" /> </div><div>{item.hometeamname}</div>
                                                </div>
                                                <p className="mb-0">vs</p>
                                                <div className="scheduleTiles">
                                                    <div className="teamLogo"><img src={item.image_url_away} alt="team" /> </div><div>{item.awayteamname}</div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                                );
                            })
                        ) : (
                            <div className="flex-standings">
                                <Audio
                                    height="180"
                                    width="120"
                                    radius="200"
                                    color="white"
                                    ariaLabel="loading"
                                    wrapperStyle
                                    wrapperClass
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Schedule);
