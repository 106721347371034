import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from "../assets/images/logo.png"
import * as Yup from "yup"
import { Field, Form, Formik } from 'formik'
import { CRUDServices } from '../services/CRUDServices'
import { objectToFormData } from '../functions'
import { toast } from 'react-toastify'

const ForgotPassword = () => {

    const [loading, setLoading] = useState(false);

    const initialValues = {
        mail: ""
    }

    const forgotSchema = Yup.object().shape({
        mail: Yup.string().email("Invalid username").required("Username is required"),
    })

    const handleSumbit = async (values) => {
        setLoading(true);
        console.log(values, "values");
        const payload = objectToFormData(values);
        const response = await CRUDServices.POST("forget_password", payload);
        setLoading(false);

        if (response.status == 200) {
            toast.success(response?.data?.message);
        }
        else {
            var errors = response.data.errors;

            for (var [key, error] of Object.entries(errors)) {
                toast.error(error);
            }
        }
    }

    return (
        <div className="main-bg">
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="card shadow">
                            <NavLink className="logo" to="/">
                                <img src={logo} className="img-logo" />
                            </NavLink>
                            <div className="card-title text-center">
                                <p className="p-3">Forgot Password</p>
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={forgotSchema}
                                    onSubmit={(values) => {
                                        handleSumbit(values);
                                    }}
                                >
                                    {({ errors, touched }) => {
                                        return (
                                            <Form>
                                                <div className="mb-4">
                                                    <label for="mail" className="form-label">Email</label>
                                                    <Field type="text" className="form-control" id="username" placeholder="Enter Your Email" name='mail' autocomplete="off" />
                                                    {errors.mail && touched.mail ? <div className='error'>{errors.mail}</div> : null}
                                                </div>
                                                <div className="d-grid">
                                                    <button type="submit" className={loading ? "btn text-light p-3 disabled" : "btn text-light p-3"}>{loading ? "Submitting..." : "Submit"}</button>
                                                </div>
                                                <div className="mb-4">
                                                    <NavLink to="/login"><p className="text-center mt-3 bottom-text">Back to Login </p></NavLink>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword