import React from 'react'

const HowToPlay = () => {
  return (
    <div className="dashboard-body">
      <div className='page-bg'>
      <h1 className='mb-2 mt-5'>How To Play</h1>
        <div className="container p-3 text-center">
          <div className="row">
            <div className="col">

            <video src='https://fandex.com//assets/videos/FANDEX_how_It_works.mp4' type="video/mp4" width="100%" height="auto" loop="loop" muted="muted" autoplay="true" controls="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToPlay


