import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import "./index.css"
import "./assets/css/style.css"
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Countdown from './components/Countdown'
import HomeLayout from './pages/HomeLayout'
import AllLeagues from './pages/AllLeagues'
import SingleLeague from './pages/SingleLeague'
import Mybets from './pages/Mybets'
import PrivateRoute from './PrivateRoute'
import { useDispatch } from 'react-redux'
import { objectToFormData } from './functions'
import { CRUDServices } from './services/CRUDServices'
import { setBalance } from "./redux/walletSlice";
import ComingSoon from "./components/ComingSoon"
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Disclaimer from './pages/Disclaimer'
import PrivacyPolicy from './pages/PrivacyPolicy'
import FAQ from './pages/FAQ'
import HowToPlay from './pages/HowToPlay'
import Footer from './components/Footer'
import Header from './components/Header'

const App = () => {

  const dispatch = useDispatch();

  const getWalletBalance = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const payload = objectToFormData({
        api_username: "FANDEX_DEV_TEAM",
        api_password: "FANDEX_PASSWORD_@#",
        user_id: localStorage.getItem("userId"),
      });
      const res = await CRUDServices.POST(
        "get_fantasy_account_balance_by_user_id",
        payload
      );
      dispatch(setBalance(res.data.data[0].account_amount));
    }
  };

  useEffect(() => {
    getWalletBalance();
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<HomeLayout />}>
          <Route index element={<AllLeagues />}></Route>
          <Route path='/nba' element={<AllLeagues />}></Route>
          <Route path='singlePage' element={<SingleLeague />}></Route>
          <Route path='nfl' element={<ComingSoon />}></Route>
          <Route path='mls' element={<ComingSoon />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
        <Route path="/reset_password" element={<ResetPassword />}></Route>
        <Route path="/reset_password/*" element={<ResetPassword />}></Route>
        <Route path='/mybets' element={<PrivateRoute />}>
          <Route index element={<Mybets />}></Route>
        </Route>
        <Route path='/' element={<><Header /><Outlet /><Footer /></>}>
          <Route path="/aboutus" element={<AboutUs />}></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/howtoplay" element={<HowToPlay />}></Route>
        </Route>
      </Routes>
    </>
  )
}

export default App