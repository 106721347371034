import axios from "axios"

export const CRUDServices = {
    GET: async (url) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`)
            return response;
        }
        catch (error) {
            return error.response;
        }

    },

    POST: async (url, value) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, value);
            return response;
        }
        catch (error) {
            return error.response;
        }

    },
}