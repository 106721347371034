import React from 'react'
import aboutbg from '../assets/images/about.png'
import Icon1 from '../assets/images/icon1.png'
import Icon2 from '../assets/images/icon2.png'
import Icon3 from '../assets/images/icon3.png'
import Icon4 from '../assets/images/icon4.png'

const AboutUs = () => {
  return (
    <div className="dashboard-body">
      <div className="container-fluid p-3">
        <div className='page-bg'>
        <h1 className='mt-4 mb-4'>About Us</h1>
          <div className="row">
            <div className="col-md-6">
              

              <p>Ab neque tempore At excepturi deleniti a amet aliquam eum totam aspernatur eos sint ipsa. Non voluptas consequuntur aut velit unde ad consequatur voluptas aut commodi libero. Vel cupiditate galisum sit quaerat dolores sed vero rerum! Et ducimus ipsum id distinctio molestiae aut rerum debitis ad assumenda necessitatibus! Sed ipsa molestias et atque explicabo hic quia omnis aut repudiandae incidunt ad eius voluptas sed quasi quia.</p>

              <p>Et tenetur quod ut praesentium nulla nam voluptas consequatur est accusamus praesentium sed voluptatem excepturi ut numquam voluptatem. Et harum sint aut corrupti cupiditate et quasi quibusdam aut inventore excepturi ad consectetur magni non distinctio sunt. Et facilis ipsa sed deserunt aspernatur quo reiciendis nostrum vel dolorem ullam eum laboriosam dolores in voluptatem autem. Et alias galisum et inventore aliquam ut accusamus odio et dolore quas sit nesciunt iure id adipisci labore et enim molestiae. Vel velit fugiat et quaerat quisquam quo neque commodi est veritatis velit ea magni dolore quo deleniti quia qui numquam repudiandae. Sed quidem illo aut amet aliquam et voluptas dolorum. Est provident vitae aut veritatis repudiandae et nesciunt totam ut tempora sint aut nesciunt minus. Sed dolores quis in galisum maxime sed consequatur omnis cum fugit adipisci rem minus ullam. Et omnis eligendi et consequatur corporis ut dicta dolores sed fuga repellat et animi placeat aut minima dolor!</p>

              <p>Ea laborum error est maiores expedita cum rerum reprehenderit! Et facere voluptate sed magni tempora est esse sapiente! Non minus inventore vel possimus neque qui velit quia. Et dolorem tempore aut reiciendis doloremque ut consequatur perspiciatis ab laudantium mollitia eos voluptas debitis. Qui distinctio nihil sit assumenda molestiae nam nihil ducimus ea magnam impedit.</p>

              <p>Ut saepe voluptatem et consequatur unde vel asperiores ipsum in eius assumenda. Qui modi voluptas et autem distinctio sit magni dolorem et omnis rerum in itaque unde eum accusantium sunt. Aut eveniet voluptates ad magni officia et molestiae quasi sit eveniet repellat nam debitis sint? Id nostrum officiis ad ducimus esse non voluptas blanditiis est voluptatum quasi ea optio officia et omnis pariatur. Non consequatur expedita ab veritatis corrupti sed voluptates velit eum temporibus accusamus est laborum voluptatibus sit culpa amet. Qui voluptatem sequi et dolores dolor hic neque reprehenderit? Et adipisci consequatur rem reiciendis expedita est vitae provident non quisquam omnis ut dolorum deserunt qui odit Quis. Eum alias mollitia id amet quaerat et autem deleniti et dolorem voluptas eum placeat internos? Est distinctio delectus non voluptatem minima ut sunt fugiat in consectetur quia ut quidem modi ex sint explicabo.
              </p>

            </div>
            <div className='col-md-6'>
              <div className='overlay'>
                <img src={aboutbg} alt='sideimg' className='img-fluid' />
              </div>
            </div>
          </div>

          <div className='about-icon-grp mt-5 mb-5'>
            <div className='text-center about-section'>
              <img src={Icon1} alt='icon1' className='abt-icons' />
              <p>Team Planning</p>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there.</p>
            </div>
            <div className='text-center about-section'>
              <img src={Icon2} alt='icon1' className='abt-icons' />
              <p>Practicing</p>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there.</p>
            </div>
            <div className='text-center about-section'>
              <img src={Icon3} alt='icon1' className='abt-icons' />
              <p>Teamwork</p>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there.</p>
            </div>
            <div className='text-center about-section'>
              <img src={Icon4} alt='icon1' className='abt-icons' />
              <p>High Quality</p>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there.</p>
            </div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default AboutUs