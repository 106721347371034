import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = () => {

    var token = localStorage.getItem("token");

    if (token) {
        return <Outlet />;
    }
    else {
        toast.success("Please login to continue");
        return <Navigate to="/login" state={{ url: window.location.href }} />
    }

}

export default PrivateRoute